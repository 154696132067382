export const routes = {
  admin: {
    dashboard: '/admin',
    users: {
      list: '/admin/users',
      new: '/admin/users/new',
      edit: '/admin/users/:id',
    },
    translations: {
      list: '/admin/translations',
    },
    profile: '/admin/profile',
    profileSettings: '/admin/profile?activeTab=2',
    settings: '/admin/settings',
  },
  auth: {
    login: '/login',
    registrationConfirmation: '/registration-confirmation/:token',
    deletionConfirmation: '/deletion-confirmation/:token',
    passwordReset: '/password-reset',
    passwordResetConfirmation: '/password-reset/:token',
  },
  homepage: '/',
};
